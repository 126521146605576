<template>
  <div class="mx-4 my-4 md:mx-8">
    <h1 class="text-3xl mb-5 ml-3">Criar acesso plataforma</h1>

    <div class="mt-5 md:mt-0 md:col-span-2">
      <form action="#" method="POST" autocomplete="off">
        <div class="px-5 pb-4">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-4">
              <label for="name" class="block text-sm font-medium">Nome</label>
              <input
                v-model="form.nome"
                type="text"
                name="name"
                id="name"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div class="col-span-12 md:col-span-4">
              <label for="email" class="block text-sm font-medium">Email</label>
              <input
                v-model="form.email"
                type="text"
                name="email"
                id="email"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div class="col-span-12 md:col-span-4">
              <label for="email" class="block text-sm font-medium">Centro de Custo</label>
              <input
                v-model="form.centrocusto"
                type="text"
                name="centrocusto"
                id="centrocusto"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div class="grid grid-cols-12 gap-6 mt-3">
            <div class="col-span-12 md:col-span-4">
              <label for="password" class="block text-sm font-medium">Senha</label>
              <input
                autocomplete="new-password"
                v-model="form.password"
                type="password"
                name="password"
                id="password"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div class="col-span-12 md:col-span-4">
              <label for="password2" class="block text-sm font-medium"
                >Confirme a senha</label
              >
              <input
                autocomplete="new-password"
                v-model="form.password2"
                type="password"
                name="password2"
                id="password2"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
          <hr class="mt-6 mb-3" />
          <h3 class="font-semibold text-lg mb-3">Acessos conversas</h3>
          <div class="grid grid-cols-12 gap-6">
            <div
              class="col-span-12 md:col-span-12"
            >
              <label for="ativarRespostas" class="block text-sm font-medium">
                <input
                  type="checkbox"
                  v-model="form.ativarRespostas"
                  class="rounded-sm"
                />
                <span class="ml-2">
                  Liberar tela de resposta para acompanhamento
                </span>
              </label>
            </div>
            <div
              class="col-span-12 md:col-span-12"
            >
              <label for="clientePersonalizado" class="block text-sm font-medium">
                <input
                  type="checkbox"
                  v-model="form.clientePersonalizado"
                  class="rounded-sm"
                />
                <span class="ml-2">
                  Cliente Personalizado (Canal com foto de perfil distinta)
                </span>
              </label>
            </div>
            <div v-if="form.clientePersonalizado" class="col-span-12 md:col-span-6">
              <label for="doc" class="mt-2 block text-sm font-medium text-gray-800">Foto de Perfil <small class="text-red-800 font-bold">Tamanho: (192x192 px)</small></label>
              <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" @vdropzone-sending="send" v-on:vdropzone-success="update"></vue-dropzone>
            </div>
            <div v-if="form.clientePersonalizado" class="col-span-12 md:col-span-6">
              <img class="h-72 mt-4" v-if="this.form.file" :src="this.form.file"/>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 grap-6">
          <div class="px-5 py-3">
            <button
              @click="save"
              type="button"
              class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400"
            >
              Salvar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import vueDropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  components: {
    vueDropzone,
  },
  data() {
    return {
      dropzoneOptions: this.$http.getDropzoneConfigPublic(`upload`, "post", {
        maxFiles: 1,
        addRemoveLinks: true,
        capture: true,
        dictDefaultMessage: 'Clique ou arraste sua foto de perfil aqui',
        dictRemoveFile: "Remover"
      }),
      form: {
        nome: "",
        email: "",
        centrocusto: "",
        password: "",
        password2: "",
        ativarRespostas: false,
        clientePersonalizado: false,
        file: null
      },
    };
  },

  methods: {

    send(file, xhr, formData) {
      formData.append('folder', 'arquivosWhats');
    },
    update(file, response) {
      if(response.file && response.file.filename) this.form.file = `${this.api}/upload?mimetype=image/jpeg&filename=${response.file.filename}&folder=arquivosWhats`;
    },
    async save() {
 
      if (!this.form.nome || !this.form.email || !this.form.password) {
        return this.$vToastify.error("Digite o nome, email e senha");
      }

      if (this.form.password2 !== this.form.password) {
        return this.$vToastify.error("Senha e confirmação são diferentes");
      }

      const req = await this.$http.post(`/criarContaNovaVida`, this.form);

      if (req.data.success) {
        this.$vToastify.success("Conta criada com sucesso!");
        this.form = {
            nome: "",
            email: "",
            centrocusto: "",
            password: "",
            password2: "",
            ativarRespostas: false,
        };
      } else {
        this.$vToastify.error(req.data.err);
      }
    },
  },
  async mounted() {
    if(this.$store.state.user && this.$store.state.user._id){
        this.$store.commit("logout");
        window.location.reload();
    }

  },
};
</script>
